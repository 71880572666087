<template>
  <section class="main-goal">
    <div class="main-title">
      <h2><span>поможем вам и&nbsp;ребенку</span> достичь целей</h2>
    </div>

    <div class="main-goal__tabs">
      <button
        v-for="item of tabs"
        :key="item.id"
        :ref="'tab-' + item.id"
        :class="[
          'main-goal__tabs--item',
          {'main-goal__tabs--item-active': item.isActive},
        ]"
        @click="setActiveTab(item.id)"
      >
        {{ item.label }}
      </button>
    </div>

    <div class="main-goal__blocks">
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <div
          v-if="activeBlock"
          :key="activeBlock.id"
          :class="`main-goal__block main-goal__block--${activeBlock.className}`"
        >
          <div class="main-goal__block_content">
            <h3 v-html="activeBlock.title" />

            <img
              v-if="activeBlock.id === 4"
              src="/v2/main/goal/sticker.svg"
              alt="sticker"
              class="main-goal__block_sticker"
            >

            <img
              v-if="activeBlock.id === 4"
              src="/v2/main/goal/sticker-mobile.svg"
              alt="sticker"
              class="main-goal__block_sticker-mobile"
            >

            <div class="main-goal__block_cheap">
              <template v-for="(cheap, index) in activeBlock.cheap">
                <nuxt-link
                  v-if="cheap.to"
                  :key="index"
                  :class="{
                    hovered: cheap.hovered,
                    'hide-mobile': cheap.hideMobile,
                    'hide-desktop': cheap.hideDesktop,
                    'have-cheap-sup': cheap.sup,
                  }"
                  :to="cheap.to"
                >
                  <b v-html="cheap.text" />
                  <img
                    src="/v2/main/programs/arrow.svg"
                    alt="arrow"
                    loading="lazy"
                  >

                  <span
                    v-if="cheap.sup"
                    :key="`sup-${cheap.sup}`"
                    class="main-goal__block_cheap_sup"
                  >
                    {{ cheap.sup }}
                  </span>
                </nuxt-link>
                <button
                  v-if="cheap.modal"
                  :key="index"
                  :class="['m-btn', {
                    hovered: cheap.hovered,
                    'hide-mobile': cheap.hideMobile,
                    'hide-desktop': cheap.hideDesktop,
                    'have-cheap-sup': cheap.sup,
                  }]"
                  @click="openModal(cheap.modal)"
                >
                  <b v-html="cheap.text" />
                  <img
                    src="/v2/main/programs/arrow.svg"
                    alt="arrow"
                    loading="lazy"
                  >

                  <span
                    v-if="cheap.sup"
                    :key="`sup-${cheap.sup}`"
                    class="main-goal__block_cheap_sup"
                  >
                    {{ cheap.sup }}
                  </span>
                </button>
              </template>
            </div>
          </div>

          <main-goal-video
            v-if="activeBlock.videos[activeBlockCurrentVideo]"
            class="main-goal__block_video"
            :videos-count="activeBlock.videos.length"
            :video="activeBlock.videos[activeBlockCurrentVideo]"
            :video-number="activeBlockCurrentVideo + 1"
            @next="nextVideo"
            @play="activeBlockCurrentVideo = $event - 1"
          />
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import MainGoalVideo from './MainGoalVideo.vue';
import { MODAL_TYPES } from '../common/AppHeader/data';

export default {
  name: 'MainGoal',
  components: { MainGoalVideo },
  data: () => ({
    activeTab: 1,
    previousTab: 1,
    activeBlockCurrentVideo: 0,
    blocks: [
      {
        id: 1,
        className: 'orange',
        title: 'получить аттестат <br>гос.&nbsp;образца',
        cheap: [
          { text: 'домашняя школа с 5-11 класс', to: '/online-school' },
          { text: 'демо-доступ к платформе', sup: 'бесплатно', modal: MODAL_TYPES.demo },
          { text: 'физмат класс', to: '/online-school/fiziko-matematicheskij' },
          { text: 'гуманитарный класс', to: '/online-school/gumanitarnyj' },
          { text: 'лингвистический класс', to: '/online-school/lingvisticheskij' },
          { text: 'соц-эконом класс', to: '/online-school/socialno-ekonomicheskij' },
          { text: 'экстернат для детей', to: '/externat' },
          { text: 'экстернат для взрослых', to: '/externat-dlya-vzroslyh' },
          { text: 'прикрепление для аттестации', to: '/online-school/attestacii-i-prikreplenie' },
        ],
        videos: [
          {
            name: 'Сергунин Егор',
            about: 'ученик 11А класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'являюсь профессиональным спортсменом по&nbsp;плаванию, сборная Краснодарского края. Учась в&nbsp;обычной школе, я&nbsp;бы ничего не&nbsp;успел',
            video: 'Сергунин Егор.mp4',
            duration: 115,
          },
          {
            name: 'Дарья Андриевская',
            about: 'закончила 11 класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'у меня возник конфликт в прошлой школе, недопонимание и мы с моей мамой решили, что заберем заявление. В итоге выбрали Синергию, комфортное зачисление и очень приятные преподаватели',
            video: 'Дарья Андриевская.mp4',
            duration: 34,
          },
          {
            name: 'Оля',
            about: 'ученица 10А класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'уроки были не&nbsp;только информативные, но&nbsp;еще&nbsp;и&nbsp;интересные, учителя старались найти подход к&nbsp;каждому ученику, стараясь объяснить так, чтобы&nbsp;все&nbsp;поняли',
            video: 'Оля.mp4',
            duration: 22,
          },
          {
            name: 'Рома',
            about: 'ученик 9Е класса',
            text: 'учителя учат жить, а не просто дают знания',
            video: 'Рома.mp4',
            duration: 49,
          },
        ],
      },
      {
        id: 2,
        className: 'blue ',
        title: 'сдать егэ/огэ',
        cheap: [
          { text: 'профориентация', sup: 'бесплатно', to: '/proforientacia' },
          { text: 'подготовка к егэ', to: '/ege' },
          { text: 'подготовка к огэ', to: '/oge' },
          { text: 'психолог', modal: MODAL_TYPES.psychologist },
          { text: 'репетиторы егэ/огэ', to: '/catalog/repetitor/ege' },
          { text: 'профильная школа', to: '/school-university' },
          { text: 'подбор и поступление в вуз', modal: MODAL_TYPES.egeVuz },
          { text: 'подбор и поступление в колледж', modal: MODAL_TYPES.ogeCollege },
          { text: 'бесплатные курсы егэ/огэ', to: '/ege?format=free' },
        ],
        videos: [
          {
            name: 'Дарья Андриевская',
            about: 'сдала русский на 100 баллов',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'ритуал подготовки к&nbsp;егэ&nbsp;очень приятен. Ты&nbsp;находишь материал и&nbsp;такой: «о, я&nbsp;сейчас посмотрю, поучу», а&nbsp;потом нарешиваешь варианты и&nbsp;разбираешь ошибки',
            video: 'Дарья Андриевская-2.mp4',
            duration: 34,
          },
          {
            name: 'Арина',
            about: 'ученица 11А класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'благодаря учителям&nbsp;я&nbsp;смогла минимизировать стресс перед&nbsp;экзаменом и&nbsp;пережить это&nbsp;жизненное испытание',
            video: 'Арина.mp4',
            duration: 41,
          },
          {
            name: 'Валерия Фомина',
            about: 'ученица 11А класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'больше всего понравился подход учителей к&nbsp;ученикам, их&nbsp;отношение, я&nbsp;видела их&nbsp;поддержку, веру в&nbsp;нас&nbsp;и&nbsp;это&nbsp;безумно ценно',
            video: 'Валерия Фомина.mp4',
            duration: 37,
          },
        ],
      },
      {
        id: 3,
        className: 'violet',
        title: 'улучшить знания',
        cheap: [
          // eslint-disable-next-line max-len,vue/max-len
          { text: '«I can!» — развитие английского', to: '/catalog/courses/anglijskij-dlya-detej', sup: '30 дней бесплатно' },
          { text: 'выиграть олимпиаду', to: '/catalog/repetitor/olympiada' },
          { text: 'сдать впр', to: '/catalog/repetitor/vpr' },
          { text: 'математика', to: '/catalog/repetitor/matematika' },
          { text: 'литература', to: '/catalog/repetitor/literatura' },
          { text: 'чтение', to: '/catalog/repetitor' },
          { text: 'русский язык', to: '/catalog/repetitor/russkij-yazyk' },
          { text: 'физика', to: '/catalog/repetitor/fizika' },
          { text: 'биология', to: '/catalog/repetitor/biologiya' },
          { text: 'информатика', to: '/catalog/repetitor/informatika' },
          { text: 'химия', to: '/catalog/repetitor/himiya' },
          { text: 'обществознание', to: '/catalog/repetitor/obshchestvoznanie' },
          { text: 'география', to: '/catalog/repetitor/geografiya' },
          { text: 'история', to: '/catalog/repetitor/istoriya' },
          { text: 'английский язык для 1-11 класс', to: '/catalog/repetitor/anglijskij_yazyk' },
          { text: 'программирование для детей', to: '/catalog/programmirovanie-dlya-detej' },
          { text: 'каталог всех репетиторов ', to: '/catalog/repetitor', hovered: true },
        ],
        videos: [
          {
            name: 'Яна Куцанова',
            about: 'ученик 9Б класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'Учителя школы просто лучшие люди планеты, тебя всегда поддержат и&nbsp;направят на&nbsp;правильный путь',
            video: 'Яна Куцанова.MOV',
            duration: 50,
          },
          {
            name: 'Арина',
            about: 'ученица 9В класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'я занимаю профессиональным спортом и часто в разъездах, я могу взять с собой уроки куда угодно и не беспокоюсь за успешную сдачу егэ в будущем, потому что у нас прекрасные преподаватели',
            video: 'Арина-2.mov',
            duration: 26,
          },
          {
            name: 'Алла Цароева',
            about: 'мама ученика 7А класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'долго искала своему сыну такую школу где&nbsp;будет индивидуальный подход',
            video: 'Алла Цароева.mov',
            duration: 72,
          },
        ],
      },
      {
        id: 4,
        className: 'green',
        title: 'развить таланты',
        cheap: [
          { text: 'ментальная арифметика', to: '/catalog/courses/mentalnaya-arifmetika' },
          { text: 'геометрия на плоскости', to: '/catalog/courses/uglublennaya-geometriya' },
          { text: 'программирование на Python', to: '/catalog/courses/python' },
          { text: 'программирование на Construct 3', to: '/catalog/courses/construct-3' },
          { text: 'программирование на Roblox', to: '/catalog/courses/roblox' },
          { text: 'финансовая грамотность', to: '/catalog/courses/fin-gramotnost-base' },
          { text: 'экономика вокруг нас', to: '/catalog/courses/ekonomika-dlya-detej' },
          { text: 'основы рисования', to: '/catalog/courses/osnovy-risunka' },
          { text: 'основы дизайна и презентаций', to: '/catalog/courses/dizajn-prezentacij' },
          { text: 'кружок «Между строк»', to: '/catalog/courses/literaturnyj-kruzhok' },
          { text: 'мастер слова: развитие речи', to: '/catalog/courses/kurs-razvitie-rechi' },
          { text: 'доступ ко всем курсам', hovered: true, modal: MODAL_TYPES.catalogCourses },
        ],
        videos: [
          {
            name: 'Дина',
            about: 'ученица 8 класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'у меня сейчас идет более уклон в творчество, в музыку, в дизайн, в актерство и собираюсь поступать на актерское',
            video: 'Дина.mp4',
            duration: 35,
          },
          {
            name: 'Арина',
            about: 'ученица 5 класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'мы очень любим путешествовать и, если учиться в обычной школе, у меня бы этого просто не вышло',
            video: 'Арина-3.mov',
            duration: 43,
          },
          {
            name: 'Сергунин Егор',
            about: 'ученик 11А класса',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'Являюсь профессиональным спортсменом по&nbsp;плаванию, сборная Краснодарского края. Учась в&nbsp;обычной школе, я&nbsp;бы ничего не&nbsp;успел',
            video: 'Сергунин Егор.mp4',
            duration: 115,
          },
        ],
      },
      {
        id: 5,
        className: 'red',
        title: 'поступить в&nbsp;вуз или колледж',
        cheap: [
          { text: 'профориентация', sup: 'бесплатно', to: '/proforientacia' },
          { text: 'колледж синергия', sup: '-30%', modal: MODAL_TYPES.discount30 },
          { text: 'университет синергия', sup: '-30%', modal: MODAL_TYPES.discount30 },
          { text: 'второе высшее', modal: MODAL_TYPES.secondEducation },
          { text: 'двойной диплом', modal: MODAL_TYPES.secondDiploma },
          { text: 'подбор и поступление в вуз', modal: MODAL_TYPES.vuz },
          { text: 'подбор и поступление в колледж', modal: MODAL_TYPES.college, hideMobile: true },
          { text: 'экстернат для взрослых', to: '/externat-dlya-vzroslyh' },
          { text: 'курсы для взрослых', modal: MODAL_TYPES.course },
        ],
        videos: [
          {
            name: 'Дмитрий',
            about: 'поступил в университет синергия',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'я&nbsp;благодарен онлайн-школе Синергия за&nbsp;такую возможность&nbsp;— проживать в&nbsp;Москве, учиться и&nbsp;работать с&nbsp;1-ого курса',
            video: 'Дмитрий.MOV',
            duration: 50,
          },
        ],
      },
    ],
  }),
  computed: {
    transitionName() {
      return this.activeTab > this.previousTab
        ? 'slide-right'
        : 'slide-left';
    },
    tabs() {
      return [
        {
          id: 1,
          label: 'получить аттестат',
          isActive: this.activeTab === 1,
        },
        {
          id: 2,
          label: 'сдать егэ и огэ',
          isActive: this.activeTab === 2,
        },
        {
          id: 3,
          label: 'улучшить знания',
          isActive: this.activeTab === 3,
        },
        {
          id: 4,
          label: 'развить таланты',
          isActive: this.activeTab === 4,
        },
        {
          id: 5,
          label: 'поступить в вуз/колледж',
          isActive: this.activeTab === 5,
        },
      ];
    },
    activeBlock() {
      return this.blocks.find(({ id }) => id === this.activeTab);
    },
  },
  watch: {
    activeTab() {
      this.activeBlockCurrentVideo = 0;
    },
  },
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),

    nextVideo() {
      this.activeBlockCurrentVideo += 1;
      if (this.activeBlockCurrentVideo >= this.activeBlock.videos.length) {
        this.activeBlockCurrentVideo = 0;
      }

      if (this.activeBlock.videos.length === 1) {
        this.activeBlockCurrentVideo = null;
        this.$nextTick(() => {
          this.activeBlockCurrentVideo = 0;
        });
      }
    },
    setActiveTab(value) {
      this.previousTab = this.activeTab;
      this.activeTab = value;

      this.$nextTick(() => {
        const tabElement = this.$refs[`tab-${value}`];
        if (tabElement && tabElement[0]) {
          tabElement[0].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

.main-goal {
  .main-title {
    max-width: calc(var(--scale) * 1220);
  }

  .main-goal__tabs {
    width: calc(100% + var(--scale) * 80);
    margin-top: calc(var(--scale) * 40);
    margin-inline: calc(var(--scale) * -40);
    padding-inline: calc(var(--scale) * 40);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    display: flex;
    gap: calc(var(--scale) * 8);

    @include media-down($size-tablet) {
      width: calc(100% + var(--scale) * 32);
      margin-top: calc(var(--scale) * 24);
      margin-inline: calc(var(--scale) * -16);
      padding-inline: calc(var(--scale) * 16);

      gap: calc(var(--scale) * 4);
    }

    @include media-down($size-tablet) {
      width: calc(100% + var(--scale) * 24);
      margin-inline: calc(var(--scale) * -12);
      padding-inline: calc(var(--scale) * 12);
    }

    &--item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(var(--scale) * 99);
      padding: 0 calc(var(--scale) * 54);

      border: none;
      border-radius: calc(var(--scale) * 200);
      background-color: $white-color;

      font-size: calc(var(--scale) * 32);
      font-weight: 500;
      line-height: 110%;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      white-space: nowrap;
      color: #A7ABAC;

      cursor: pointer;
      transition: background-color .2s ease-out, color .2s ease-out;

      @include media-down($size-tablet) {
        height: calc(var(--scale) * 52);
        padding: 0 calc(var(--scale) * 22);

        font-size: calc(var(--scale) * 18);
      }

      &:hover, &-active {
        background-color: #5237E6;
        color: $white-color;
      }
    }
  }

  .main-goal__blocks {
    margin-top: calc(var(--scale) * 20);

    @include media-down($size-tablet) {
      margin-top: calc(var(--scale) * 16);
    }
  }

  .main-goal__block {
    position: relative;
    display: flex;
    gap: calc(var(--scale) * 20);
    height: calc(var(--scale) * 720);

    @include media-down($size-tablet) {
      height: calc(var(--scale) * 512);
    }

    @include media-down($size-mobile) {
      height: calc(var(--scale) * 720);
    }

    .main-goal__block_content {
      width: calc(var(--scale) * 1375);
      border-radius: calc(var(--scale) * 60);
      padding: calc(var(--scale) * 32) calc(var(--scale) * 40) calc(var(--scale) * 40);
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      background-size: cover;
      background-position: center;

      @include media-down($size-tablet) {
        width: 100%;
        border-radius: calc(var(--scale) * 32);
        padding:
          calc(var(--scale) * 20)
          calc(var(--scale) * 14)
          calc(var(--scale) * 16)
          calc(var(--scale) * 16);
      }
    }

    h3 {
      font-weight: 500;
      font-size: calc(var(--scale) * 86);
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        max-width: calc(var(--scale) * 490);
        font-size: calc(var(--scale) * 44);
        line-height: 90%;
        letter-spacing: -0.06em;
      }

      @include media-down($size-mobile) {
        max-width: calc(var(--scale) * 250);
      }
    }

    .main-goal__block_sticker {
      position: absolute;
      top: calc(var(--scale) * 43);
      right: calc(var(--scale) * 22.39);
      width: calc(var(--scale) * 384.61);

      @include media-down($size-tablet) {
        top: calc(var(--scale) * 50);
        right: calc(var(--scale) * 106.02);
        width: calc(var(--scale) * 270.98);
      }

      @include media-down($size-mobile) {
        display: none;
      }
    }

    .main-goal__block_sticker-mobile {
      display: none;

      @include media-down($size-mobile) {
        display: block;
        position: absolute;
        top: calc(var(--scale) * 40);
        left: calc(var(--scale) * 16);
        width: calc(var(--scale) * 212.19);
      }
    }

    .main-goal__block_cheap {
      display: flex;
      flex-wrap: wrap;
      gap: calc(var(--scale) * 4) ;
      position: relative;

      a,
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(var(--scale) * 19) calc(var(--scale) * 32) calc(var(--scale) * 22);
        gap: calc(var(--scale) * 16);

        background: rgba(#ffffff, 0.15);
        border: 1px solid rgba(#ffffff, 0.5);
        box-shadow: inset 0 calc(var(--scale) * 5) calc(var(--scale) * 20) rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(5px);
        border-radius: calc(var(--scale) * 24);
        transition: 200ms;

        position: relative;
        z-index: 2;

        @include media-down($size-tablet) {
          padding: calc(var(--scale) * 9) calc(var(--scale) * 14) calc(var(--scale) * 11);
          border-radius: calc(var(--scale) * 16);
          gap: calc(var(--scale) * 10);
        }

        @include media-down($size-mobile) {
          padding: calc(var(--scale) * 9) calc(var(--scale) * 10) calc(var(--scale) * 11);
        }

        &.hide-mobile {
          @include media-down($size-tablet) {
            display: none;
          }
        }

        &.hide-desktop {
          display: none;

          @include media-down($size-tablet) {
            display: block;
          }
        }

        &:has(.main-goal__block_cheap_sup) {
          z-index: 2;
        }

        b {
          transition: 200ms;
          font-weight: 500;
          font-size: calc(var(--scale) * 26);
          line-height: 120%;
          color: #FFFFFF;

          @include media-down($size-tablet) {
            font-size: calc(var(--scale) * 18);
            letter-spacing: -0.02em;
            line-height: 110%;
          }
        }

        .main-goal__block_cheap_sup {
          position: absolute;
          padding: calc(var(--scale) * 8) calc(var(--scale) * 14) calc(var(--scale) * 10);
          border-radius: calc(var(--scale) * 40);

          font-weight: 400;
          font-size: calc(var(--scale) * 20);
          line-height: 130%;

          @include media-down($size-tablet) {
            padding: calc(var(--scale) * 4) calc(var(--scale) * 10) calc(var(--scale) * 6);
            font-size: calc(var(--scale) * 14);
            font-weight: 500;
          }
        }

        img {
          filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(339deg) brightness(99%) contrast(105%);
          transition-delay: 100ms;
        }
      }

      button.have-cheap-sup,
      a.have-cheap-sup {
        z-index: 3;
      }
    }
  }

  .main-goal__block--orange {
    .main-goal__block_content {
      background-image: url("/v2/main/goal/orange.webp");

      @include media-down($size-tablet) {
        background-image: url("/v2/main/goal/orange-tablet.webp");
      }

      @include media-down($size-mobile) {
        background-image: url("/v2/main/goal/orange-mob.webp");
      }

      a,
      button {
        &:hover {
          background-color: #FFF0D1;

          b {
            color: #FF5319;
          }

          img {
            filter: invert(43%) sepia(52%) saturate(4319%) hue-rotate(350deg) brightness(102%) contrast(101%);
          }
        }

        .main-goal__block_cheap_sup {
          right: calc(-1 * var(--scale) * 24);
          top: calc(-1 * var(--scale) * 21);
          transform: rotate(5deg);
          background-color: #FFF0D1;
          color: #FF5319;

          @include media-down($size-tablet) {
            right: calc(-1 * var(--scale) * 12);
            top: calc(-1 * var(--scale) * 14);
          }
        }
      }
    }

    ::v-deep .video__text {
      background-color: #FFF0D1;
    }
  }

  .main-goal__block--blue {
    .main-goal__block_content {
      background-image: url("/v2/main/goal/blue.webp");

      @include media-down($size-tablet) {
        background-image: url("/v2/main/goal/blue-tablet.webp");
      }

      @include media-down($size-mobile) {
        background-image: url("/v2/main/goal/blue-mob.webp");
      }

      a,
      button {
        &:hover {
          background-color: #CEF9FF;

          b {
            color: #0A4183;
          }

          img {
            filter: invert(14%) sepia(50%) saturate(5798%) hue-rotate(204deg) brightness(80%) contrast(92%);
          }
        }

        .main-goal__block_cheap_sup {
          left: calc(-1 * var(--scale) * 20);
          top: calc(-1 * var(--scale) * 22);
          transform: rotate(-5deg);
          background-color: #CEF9FF;
          color: #0A4183;

          @include media-down($size-tablet) {
            left: calc(-1 * var(--scale) * 12);
            top: calc(-1 * var(--scale) * 14);
          }
        }
      }
    }

    ::v-deep .video__text {
      background-color: #CEF9FF;
    }
  }

  .main-goal__block--violet {
    .main-goal__block_content {
      background-image: url("/v2/main/goal/violet.webp");

      @include media-down($size-tablet) {
        background-image: url("/v2/main/goal/violet-tablet.webp");
      }

      @include media-down($size-mobile) {
        background-image: url("/v2/main/goal/violet-mob.webp");
      }

      a,
      button {
        &.hovered,
        &:hover {
          background-color: #E6CEFF;

          b {
            color: #5237E6;
          }

          img {
            filter: invert(19%) sepia(63%) saturate(6664%) hue-rotate(250deg) brightness(93%) contrast(93%);
          }
        }

        .main-goal__block_cheap_sup {
          right: calc(-1 * var(--scale) * 24);
          top: calc(-1 * var(--scale) * 24);
          transform: rotate(5deg);
          background-color: #E6CEFF;
          color: #5237E6;

          @include media-down($size-tablet) {
            right: calc(-1 * var(--scale) * 12);
            top: calc(-1 * var(--scale) * 14);
          }
        }
      }
    }

    ::v-deep .video__text {
      background-color: #E6CEFF;
    }
  }

  .main-goal__block--green {
    .main-goal__block_content {
      background-image: url("/v2/main/goal/green.webp");

      @include media-down($size-tablet) {
        background-image: url("/v2/main/goal/green-tablet.webp");
      }

      @include media-down($size-mobile) {
        background-image: url("/v2/main/goal/green-mob.webp");
      }

      a,
      button {
        &.hovered,
        &:hover {
          background-color: #D1FAD8;

          b {
            color: #01410D;
          }

          img {
            filter: invert(16%) sepia(38%) saturate(2833%) hue-rotate(108deg) brightness(90%) contrast(99%);
          }
        }

        .main-goal__block_cheap_sup {
          left: calc(-1 * var(--scale) * 20);
          top: calc(-1 * var(--scale) * 22);
          transform: rotate(-5deg);
          background-color: #D1FAD8;
          color: #01410D;

          @include media-down($size-tablet) {
            left: calc(-1 * var(--scale) * 12);
            top: calc(-1 * var(--scale) * 14);
          }
        }
      }

      .main-goal__block_cheap {
        @include media-down($size-mobile) {
          margin-top: calc(var(--scale) * 36);
        }
      }
    }

    ::v-deep .video__text {
      background-color: #D1FAD8;
    }
  }

  .main-goal__block--red {
    .main-goal__block_content {
      background-image: url("/v2/main/goal/red.webp");

      @include media-down($size-tablet) {
        background-image: url("/v2/main/goal/red-tablet.webp");
      }

      @include media-down($size-mobile) {
        background-image: url("/v2/main/goal/red-mob.webp");
      }

      a,
      button {
        &.hovered,
        &:hover {
          background-color: #FFCDCF;

          b {
            color: #8D1717;
          }

          img {
            filter: invert(14%) sepia(53%) saturate(3215%) hue-rotate(342deg) brightness(105%) contrast(103%);
          }
        }

        .main-goal__block_cheap_sup {
          left: calc(-1 * var(--scale) * 20);
          top: calc(-1 * var(--scale) * 22);
          transform: rotate(-5deg);
          background-color: #FFCDCF;
          color: #8D1717;

          @include media-down($size-tablet) {
            left: calc(-1 * var(--scale) * 12);
            top: calc(-1 * var(--scale) * 14);
          }
        }
      }
    }

    ::v-deep .video__text {
      background-color: #FFCDCF;
    }
  }

  .main-goal__block_video {
    width: calc(var(--scale) * 445);

    @include media-down($size-tablet) {
      position: absolute;
      width: calc(var(--scale) * 88);
      height: calc(var(--scale) * 120);
      top: calc(var(--scale) * 16);
      right: calc(var(--scale) * 16);
    }
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform .3s ease, opacity .3s ease;
}

.slide-right-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(25%);
}

.slide-right-leave-to,
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(-25%);
}

.slide-right-enter-to,
.slide-right-leave-from,
.slide-left-enter-to,
.slide-left-leave-from {
  opacity: 1;
  transform: translateX(0);
}

</style>
