var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-goal"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"main-goal__tabs"},_vm._l((_vm.tabs),function(item){return _c('button',{key:item.id,ref:'tab-' + item.id,refInFor:true,class:[
        'main-goal__tabs--item',
        {'main-goal__tabs--item-active': item.isActive},
      ],on:{"click":function($event){return _vm.setActiveTab(item.id)}}},[_vm._v("\n      "+_vm._s(item.label)+"\n    ")])}),0),_vm._v(" "),_c('div',{staticClass:"main-goal__blocks"},[_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[(_vm.activeBlock)?_c('div',{key:_vm.activeBlock.id,class:`main-goal__block main-goal__block--${_vm.activeBlock.className}`},[_c('div',{staticClass:"main-goal__block_content"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.activeBlock.title)}}),_vm._v(" "),(_vm.activeBlock.id === 4)?_c('img',{staticClass:"main-goal__block_sticker",attrs:{"src":"/v2/main/goal/sticker.svg","alt":"sticker"}}):_vm._e(),_vm._v(" "),(_vm.activeBlock.id === 4)?_c('img',{staticClass:"main-goal__block_sticker-mobile",attrs:{"src":"/v2/main/goal/sticker-mobile.svg","alt":"sticker"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-goal__block_cheap"},[_vm._l((_vm.activeBlock.cheap),function(cheap,index){return [(cheap.to)?_c('nuxt-link',{key:index,class:{
                  hovered: cheap.hovered,
                  'hide-mobile': cheap.hideMobile,
                  'hide-desktop': cheap.hideDesktop,
                  'have-cheap-sup': cheap.sup,
                },attrs:{"to":cheap.to}},[_c('b',{domProps:{"innerHTML":_vm._s(cheap.text)}}),_vm._v(" "),_c('img',{attrs:{"src":"/v2/main/programs/arrow.svg","alt":"arrow","loading":"lazy"}}),_vm._v(" "),(cheap.sup)?_c('span',{key:`sup-${cheap.sup}`,staticClass:"main-goal__block_cheap_sup"},[_vm._v("\n                  "+_vm._s(cheap.sup)+"\n                ")]):_vm._e()]):_vm._e(),_vm._v(" "),(cheap.modal)?_c('button',{key:index,class:['m-btn', {
                  hovered: cheap.hovered,
                  'hide-mobile': cheap.hideMobile,
                  'hide-desktop': cheap.hideDesktop,
                  'have-cheap-sup': cheap.sup,
                }],on:{"click":function($event){return _vm.openModal(cheap.modal)}}},[_c('b',{domProps:{"innerHTML":_vm._s(cheap.text)}}),_vm._v(" "),_c('img',{attrs:{"src":"/v2/main/programs/arrow.svg","alt":"arrow","loading":"lazy"}}),_vm._v(" "),(cheap.sup)?_c('span',{key:`sup-${cheap.sup}`,staticClass:"main-goal__block_cheap_sup"},[_vm._v("\n                  "+_vm._s(cheap.sup)+"\n                ")]):_vm._e()]):_vm._e()]})],2)]),_vm._v(" "),(_vm.activeBlock.videos[_vm.activeBlockCurrentVideo])?_c('main-goal-video',{staticClass:"main-goal__block_video",attrs:{"videos-count":_vm.activeBlock.videos.length,"video":_vm.activeBlock.videos[_vm.activeBlockCurrentVideo],"video-number":_vm.activeBlockCurrentVideo + 1},on:{"next":_vm.nextVideo,"play":function($event){_vm.activeBlockCurrentVideo = $event - 1}}}):_vm._e()],1):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title"},[_c('h2',[_c('span',[_vm._v("поможем вам и ребенку")]),_vm._v(" достичь целей")])])
}]

export { render, staticRenderFns }